html {
  font-size: 62.5%; /* 1rem = 10px */
}

/* Default font size 16px */
/* body {
  font-size: 1.6rem; 
  font-family: 'Montserrat', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
} */


/* global.css */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-size: 62.5%; /* 1rem = 10px */
  font-family: 'Montserrat', Arial, sans-serif;
  background-color: #f4f4f4;
}

img {
  max-width: 100%;
  height: auto;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
  height: 8px; /* Adjust the height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f4f4f4; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #bbb9b9 ; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 2px solid #f4f4f4; /* Padding around the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb9b9 ; /* Color of the scrollbar thumb on hover */
}

/* Firefox scrollbar styles */
* {
  scrollbar-width: thin; /* Make scrollbar thin */
  scrollbar-color: #bbb9b9  #f4f4f4; /* Color of the scrollbar thumb and track */
}