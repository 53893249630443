.breadcrumb {
  font-size: 1.4rem;
  color: var(--text-color);
  margin-bottom: 1.2rem;
}

.breadcrum-arrow {
  width: 1rem;
  height: 1rem;
}

.breadcrumb span:not(:last-child) {
  margin-right: 0.8rem;
}
