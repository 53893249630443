.sidebar {
  background-color: #fff;
  width: 14rem;
  padding: 0.5rem 0rem;
  box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1);
  transition: width 0.3s;
  font-family: 'Montserrat', sans-serif;
  position: relative;
}

.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logo {
  width: 12rem;
  height: 2rem;
}

.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.sidebar.collapsed {
  width: 5rem;
}

.sidebar.collapsed .menu-item {
  padding-left: 1rem;
  font-size: 2rem
}

.menu ul {
  list-style-type: none;
  padding-left: unset;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 2rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 1.2rem;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item.active {
  background-color: #09501d;
  color: white;
}

.menu-item.active-parent {
  background-color: #f0f0f0;
}

.icon {
  margin-right: 0.8rem;
}

.menu-text {
  transition: opacity 0.3s;
}

.sidebar.collapsed .menu-text {
  opacity: 0;
}

.submenu {
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 1.2rem;
}

.submenu-item {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 3rem;
  color: #212121;
  text-decoration: none;
}

.submenu-item:hover {
  color: #333;
}

.submenu-item.active {
  background-color: #09501d;
  color: white;
}

.submenu-icon {
  margin-left: auto;
  font-size: 1.2rem;
}

.sidebar.collapsed .submenu-icon {
  display: none;
}