.header {
  background-color: #ffffff;
  color: #333;
  display: flex;
  justify-content: space-between; /* Changed from flex-end to space-between */
  align-items: center;
  padding: 0 2rem;
  height: 4.75rem;
  border-bottom: 0.1rem solid #e0e0e0;
}

.header-content {
  display: flex;
  justify-content: space-between; /* Changed from flex-end to space-between */
  align-items: center;
  width: 100%; /* Added to make the content take full width */
}

.header-left {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.header-right {
  display: flex;
  align-items: center;
}

#header-username {
  font-weight: 700;
  text-transform: capitalize;
}

.notification-icon,
.avatar-icon {
  margin-right: 2rem;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.avatar-icon {
  border-radius: 50%;
}

.header-left {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

#header-username {
  font-weight: 700;
  text-transform: capitalize;
  margin-right: 1rem; /* Add some space between the username and the dropdown */
}

.warehouse-dropdown {
  width: unset;
  font-size: 1.6rem; /* Set the font size */
  padding: 0.5rem; /* Add some padding for better appearance */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Add border radius */
  background-color: #fff; /* Set background color */
  color: #333; /* Set text color */
  cursor: pointer; /* Change cursor to pointer */
}

/* Container header Section starts */
.container-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-buttons,
.right-buttons {
  display: flex;
  gap: 10px;
}

.action-button {
  padding: 5px 10px;
  background-color: #e0e0e0;
  cursor: pointer;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.action-button.active {
  background-color: #09501d;
  color: white;
}

.action-button:hover {
  background-color: #09501d;
  color: white
}

/* Container header section ends */