.layout {
  display: flex;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content{
  flex: 1;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  background-color: var(--background-color);
  min-height: 100vh;
}

@media (max-width: 768px) {  
  .content {
    padding: 1rem;
  }
}